<template>
  <div>
    <ClientOnly>
      <ninja-keys ref="ninjaKeys" @selected="selected" @change="change" :placeholder="placeholder" :data="ninjaData" class="dark"></ninja-keys>

    </ClientOnly>
    <div class="w-full flex justify-end items-center lg:pr-4 pr-2">
      <button @click="openSearch()" class="bg-white/10 lg:px-4 lg:py-2 lg:w-auto lg:h-auto w-9 h-9 lg:border lg:border-white/20 lg:hover:bg-white/15 duration-100 cursor-pointer rounded-full flex justify-center items-center space-x-2" aria-label="search">
        <Icon name="icons8:search" class="text-white" />

        <p class="text-zinc-200 text-sm lg:block hidden">Search pages & projects</p>

        <p class="text-xs text-zinc-300 lg:block hidden">{{ metaKey }} K</p>
      </button>
    </div>

  </div>
</template>

<script setup>
// import { HomeManager } from "~/components/Home/homeManager";

// const homeManager = ref(null);

const router = useRouter();

const ninjaKeys = ref(null);

const openSearch = () => {
  ninjaKeys.value.open();
};

const update = (args) => {
  // console.log("update", args)

  if(!args.projects){
    return;
  }

  let keys = ["active", "completed", "featured", "recent", "upcoming"]
  let _projects = [];

  try {
    for (let i = 0; i < keys.length; i++) {
      if (args?.projects[keys[i]]?.projects){
        _projects = [..._projects, ...args?.projects[keys[i]]?.projects];
      }
    }
  } catch (error) {
    console.log("error", error);
  }

  // let _projects = [...args?.projects?.active?.projects, ...args?.projects?.completed?.projects, ...args?.projects?.featured?.projects, ...args?.projects?.recent?.projects, ...args?.projects?.upcoming?.projects];

  let _projectMap = new Map();

  for (let i = 0; i < _projects.length; i++) {
    if (!_projectMap.has(_projects[i].project_id)) {
      _projectMap.set(_projects[i].project_id, {
        id: `project_${_projects[i].project_id}`,
        title: _projects[i].project_name,
        icon: `<img src="https://oxbull-presale.s3.amazonaws.com/${_projects[i].project_logo}?twic=v1/quality=30/cover=30x30" style="width:2rem;height:2rem;margin-right:1rem; object-contain:contain;"/>`,
        // handler: async () => {
        //   // console.log("navigation to project", _projects[i].project_id);
        //   console.log("navigation to project", _projects[i].project_id);
        //   await router.push("/app/project/" + _projects[i].project_id);
        //   // window.location.href = "/app/project/" + _projects[i].project_id;
        // },
        // children: [
        //   {
        //     id: `project_${_projects[i].project_id}_contribute`,
        //     title: `Contribute ${_projects[i].project_name}`,
        //     icon: `<img src="${_projects[i].project_logo}" style="width:2rem;height:2rem;margin-right:1rem; object-contain:contain;"/>`,
        //     handler: () => {
        //       // console.log("navigation to project history", _projects[i].project_id);
        //       router.push("/app/project/" + _projects[i].project_id + "?tab=contribute");
        //     },
        //   },
        //   {
        //     id: `project_${_projects[i].project_id}_history`,
        //     title: `Transaction History of ${_projects[i].project_name} `,
        //     icon: `<img src="${_projects[i].project_logo}" style="width:2rem;height:2rem;margin-right:1rem; object-contain:contain;"/>`,
        //     handler: () => {
        //       // console.log("navigation to project history", _projects[i].project_id);
        //       router.push("/app/project/" + _projects[i].project_id + "?tab=history");
        //     },
        //   },
        //   //claim
        //   {
        //     id: `project_${_projects[i].project_id}_claim`,
        //     title: `Claim ${_projects[i].project_name} `,
        //     icon: `<img src="${_projects[i].project_logo}" style="width:2rem;height:2rem;margin-right:1rem; object-contain:contain;"/>`,
        //     handler: () => {
        //       // console.log("navigation to project history", _projects[i].project_id);
        //       router.push("/app/project/" + _projects[i].project_id + "?tab=claim");
        //     },
        //   },
        // ]
      });
    }
  }

  // convert map into array

  let _projectData = Array.from(_projectMap.values());
  // console.clear();
  // console.log("project data", _projectData);

  const navItems = [
  {
    name: "home",
    path: "/app",
    icon: "entypo:home",
    title: "Home",
  },
  {
    name: "account",
    path: "/app/account",
    icon: "fa-solid:user-circle",
    title: "Account",
  },
  {
    name: "claim-vestings",
    path: "/app/claim",
    icon: "mdi:coins-plus",
    title: "Claim Center - Vestings",
  },
  {
    name: "claim-oversub",
    path: "/app/claim?tab=oversub_claim",
    icon: "mdi:coins-plus",
    title: "Claim Center - Oversub Claim",
  },
  {
    name: "stake",
    path: "/app/stake",
    icon: "bx:bxs-bank",
    title: "Stake",
  },
  {
    name: "calendar",
    path: "/app/calendar",
    icon: "mdi:calendar",
    title: "Calendar",
  },
  {
    name: "policy",
    path: "/app/policy",
    icon: "ic:baseline-policy",
    title: "Tiers & Policy",
  },

  {
    name: "privateSalePass",
    path: "/app/private-sale-pass",
    icon: "mdi:passport",
    title: "Private Sale Pass",
  },

  {
    name: "otc",
    path: "/app/otc",
    icon: "ant-design:shop-filled",
    title: "OTC",
  },
];

let _pageNav = []

for (let i = 0; i < navItems.length; i++) {
  _pageNav.push({
    id: navItems[i].name,
    title: navItems[i].title,
    // icon: `<Icon name="${navItems[i].icon}" style="width:2rem;height:2rem;margin-right:1rem; object-contain:contain;" />`,
    handler: () => {
      // router.push(navItems[i].path);
      navigateTo(navItems[i].path);
    },
  });

}



  ninjaData.value = [
    ..._pageNav,
    ..._projectData,
  ];
};

// homeManager.value = new HomeManager({
//   update: update,
//   authState: () => {},
// });

const { $fetchProject } = useNuxtApp();

const projectState = useState('project')

const selected = async (event) => {
  console.log("selected", event.detail);

  if(event?.detail?.action?.id?.includes("project_")){
    let _projectId = event.detail.action.id.split("_")[1];
    // await router.push("/app/project/" + _projectId);
    navigateTo("/app/project/" + _projectId);
  }

  ninjaKeys.value.close();
};

const change = (event) => {
  console.log("change", event.detail);
};

const metaKey = ref("CTRL");
const placeholder = ref("Search for page and projects...");

const ninjaData = ref([
  {
    id: "Home",
    title: "Open Home",
    // hotkey: "cmd+h",
    // mdIcon: "home",
    handler: () => {
      console.log("navigation to home");
    },
  },
  {
    id: "Open Projects",
    title: "Open Projects",
    // hotkey: "cmd+p",
    // mdIcon: "apps",
    handler: () => {
      console.log("navigation to projects");
    },
  },
  {
    id: "Theme",
    title: "Change theme...",
    // mdIcon: "desktop_windows",
    children: [
      {
        id: "Light Theme",
        title: "Change theme to Light",
        // mdIcon: "light_mode",
        handler: () => {
          console.log("theme light");
        },
      },
      {
        id: "Dark Theme",
        title: "Change theme to Dark",
        // mdIcon: "dark_mode",
        keywords: "lol",
        handler: () => {
          console.log("theme dark");
        },
      },
    ],
  },
]);

onMounted(() => {
  console.log("mounted search bar");

  // homeManager.value.refresh();

  // watch($accessToken, (newVal, oldVal) => {
  //   homeManager.value.refresh();
  // });

  watch(projectState, () => {
    update({
      projects: projectState.value
    });
  });

  const platform = navigator.platform.toLowerCase();

  if (platform.includes("mac")) {
    metaKey.value = "⌘"; // Use ⌘ for Mac
  }

  update({
    projects: projectState.value
  });

  if(!projectState.value){
    $fetchProject();
  }

});
</script>

<style>
ninja-keys {
  font-size: 1.2rem;
  --ninja-accent-color: #27e9af;
  --ninja-z-index: 9999;
  --ninja-top: 25vh;
}
</style>
